import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { Box, Typography, Link } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import { useSettingsContext } from '../../components/settings';
//
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const storedLogo = localStorage.getItem('landingLogo');
  const storedFavIconLogo = localStorage.getItem('faviconLogo');

  const [landingLogo, setLandingLogo] = useState(storedLogo);
  const [faviconLogo, setFaviconLogo] = useState(storedFavIconLogo);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (storedLogo && storedLogo !== 'null') {
      setLandingLogo(storedLogo);
    }

    if (storedFavIconLogo && storedFavIconLogo !== 'null') {
      setFaviconLogo(storedFavIconLogo);
    }
  }, [storedFavIconLogo, storedLogo]);

  const renderNavVertical = (
    <NavVertical openNav={open} onCloseNav={handleClose} landingLogo={landingLogo} />
  );

  if (isNavHorizontal) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        {isDesktop ? <NavHorizontal landingLogo={landingLogo} /> : renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <NavMini faviconLogo={faviconLogo} /> : renderNavVertical}

          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </Box>

      <footer>
        {/* <Box sx={{ pt: 3 }}>
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '40px',
              borderTop: '1px solid #ececec',
            }}
            variant="body2"
          >
            Powered by
            <Link rel="noopener" sx={{ ml: 1 }}>
              <Box
                component="img"
                src={landingLogo} // => your path
                sx={{ width: 80, height: 40, cursor: 'pointer' }}
              />
            </Link>
          </Typography>
        </Box> */}
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            backgroundColor: '#fff',
            width: {
              md: `100%`,
              sm: `100%`,
              xs: `100%`,
            },
            // width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,

            // ...(isCollapse && {
            //   width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
            // }),
            opacity: 0.95,
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '40px',
              borderTop: '1px solid #ececec',
            }}
            variant="body2"
          >
            Powered by
            {/* <Link
              rel="noopener"
              // href={landingLogo ? '' : 'https://emovur.com'}
              // target="_blank"
              sx={{ ml: 1 }}
            > */}
            <img src={landingLogo} alt="logo" width={80} style={{ marginLeft: '5px' }} />
            {/* </Link> */}
          </Typography>
        </Box>
      </footer>
    </>
  );
}
